import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const initialState = {
  title: "",
  body: "",
};
const Modal = ({ open, onClose, product, user }) => {
  const [form, setForm] = useState(initialState);
  const [score, setScore] = useState(0);
  const { id } = useParams();
  const email = user?.email;
  const uname = user?.firstName + " " + user?.lastName;

  const userId = user?.userId;
  const updatedBal = Number(user?.balance) + Number(user?.rewardFee);

  const navigate = useNavigate();
  const handleScoreChage = function (e) {
    setScore(e.target.value);
    setForm({ ...form, score: score });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const options = {
    // position: toast.POSITION.TOP_RIGHT,
    autoClose: 6000,
    onClose: (props) => {
      window.location.reload();
    },
  };
  const addcredit = async (e) => {
    const res = await axios
      .put(`https://api.areviewcenter.com/users/credit/${userId}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success(
            "YOU GOT " + `+$${user?.rewardFee} ADDED TO YOUR BALANCE`,
            options
          );
          window.location.reload();
        }
      })
      .catch(function (error) {
        toast.error("Review Not Added");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return toast.info(
        "Only logged in users can add reviews please signup or login"
      );
    }
    if (form.title && form.body && form.score) {
      const res = await axios
        .post(`https://api.areviewcenter.com/products/reviews/${id}`, {
          ...form,
          product: product?.id,
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
        })
        .then((res) => {
          if (res.status === 200) {
            addcredit();
          }
        })
        .catch(function (error) {
          toast.error("Review Not Added");
        });
    } else {
      toast.error("Please Fill in all the form");
    }
  };

  if (!open) return null;
  return (
    <div className="modal" tabIndex={-1} id="kt_modal_1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <button onClick={onClose} className="close-modal">
              X
            </button>
            <h2 className="text-light">Write a review</h2>
            <p className="text-light">
              get rewards for writing reviews, start now.
            </p>
            <br />
            <div className="row pop">
              <form onSubmit={handleSubmit} className="review">
                <label htmlFor="username">Enter Your Name</label>
                <input
                  placeholder="Enter Full Name"
                  disabled
                  type="text"
                  value={uname}
                  // value={userName}
                />
                <label htmlFor="email">Enter Your Email</label>
                <input
                  placeholder="Email Address"
                  // onChange={handleEmailChange}
                  type="email"
                  name="email"
                  id="email"
                  disabled
                  value={email}
                />
                <label htmlFor="score">What's your score</label>
                <h3
                  className={score > 3 ? "pro_Score high-score" : "pro_Score"}
                >
                  {score}
                </h3>
                <div className="slidecontainer">
                  <input
                    placeholder="0"
                    type="range"
                    name="score"
                    id="score"
                    className="sliderx"
                    onChange={handleScoreChage}
                    min="0"
                    max="5"
                    step="1"
                  />
                </div>

                <label htmlFor="body">Review Message</label>
                <input type="text" name="title" onChange={handleChange} />
                <textarea
                  onChange={handleChange}
                  name="body"
                  id="body"
                  cols="30"
                  rows="10"
                  placeholder="Review Message (Min 100 characters)"
                ></textarea>
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-dark"
                >
                  CREATE
                </button>
              </form>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
