import { React, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const Timer = ({ order, time, total, user, amount }) => {
  // console.log(order, time, total, user);
  const [status, setStatus] = useState(false);
  const [finished, setFinished] = useState(false);

  const checkSuccess = async () => {
    const res = await axios.get(
      `https://api.areviewcenter.com/products/orders/${order}`
    );
    setStatus(res.data[0].approved);
    // console.log(status);
  };

  // update user acct
  const updateUserAcct = async () => {
    const res = await axios
      .put(
        `https://api.areviewcenter.com/users/credit/premium/${user.userId}`,
        {
          balance: total,
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        toast.error("Review Not Added");
      });
  };

  const debitUser = async () => {
    const res = await axios
      .put(
        `https://api.areviewcenter.com/users/credit/premium/${user.userId}`,
        {
          balance: user.balance - amount,
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {});
  };

  function reloadTimeOut() {
    window.location.reload();
  }
  const confirmBonus = (e) => {
    e.preventDefault();
    checkSuccess();
    console.log(status);
    if (status === 1) {
      updateUserAcct();
      toast.success("Bonus credited");
      setTimeout(reloadTimeOut, 5000);
    } else {
      toast.error("Bonus Failed");
      debitUser();
      setTimeout(reloadTimeOut, 5000);
    }
  };

  useEffect(() => {
    var timeLimitInMinutes = time;
    var timeLimitInSeconds = timeLimitInMinutes * 60;
    var timerElement = document.getElementById("timer");

    function startTimer() {
      timeLimitInSeconds--;
      var minutes = Math.floor(timeLimitInSeconds / 60);
      var seconds = timeLimitInSeconds % 60;

      if (timeLimitInSeconds < 0) {
        timerElement.textContent = "00:00";
        clearInterval(timerInterval);
        setFinished(true);
        checkSuccess();
        return;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      timerElement.textContent = minutes + ":" + seconds;
    }
    var timerInterval = setInterval(startTimer, 1000);
  }, []);

  return (
    <>
      <div style={{ display: finished ? "block" : "none" }} className="bonus">
        <br />
        <button onClick={confirmBonus} className="submit_btn">
          Confirm Bonus
        </button>
      </div>

      <div style={{ display: finished ? "none" : "block" }} className="time">
        <span id="timer">00:00</span>
      </div>
    </>
  );
};
export default Timer;
