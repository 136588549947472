import axios from "axios";
import Cookies from "js-cookie";
import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const navigate = useNavigate();
  const login = async (input) => {
    const res = await axios
      .post("https://api.areviewcenter.com/auth/login", input)
      .then((res) => {
        Cookies.set("access_token", res.data.token, {
          expires: 1,
          secure: true,
        });
        if (res.status === 200) {
          setCurrentUser(res.data);
          navigate("/");
        }
      });
  };

  const logout = async () => {
    const res = await axios.post("https://api.areviewcenter.com/auth/logout");
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
