import { React, useEffect, useState, useContext } from "react";
import "../admin-style.css";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const initialState = {
  name: "",
  account: "",
  bank: "",
};

const AdminHome = ({ user, handleLogout }) => {
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState(initialState);
  const { Bank, Name, Account } = form;

  const [search, setSearch] = useState([]);
  const [list, setList] = useState(true);
  const navigate = useNavigate();

  const handleDeleteUser = async (id) => {
    console.log(id);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleAddBank = async (id) => {
    const res = await axios
      .put(`https://api.areviewcenter.com/products/bank`, {
        ...form,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Bank Updated");
        }
      })
      .catch(function (error) {
        toast.error("Bank Not Added");
      });
  };

  // get all users
  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.get("https://api.areviewcenter.com/users");
      setUsers(res.data);
    };
    fetchUsers();
    window.scrollTo(0, 0);
  }, []);

  const searchUser = async () => {
    const term = document.getElementById("searchItem").value;
    navigate(`/admin/search/users/${term}`);
  };

  const clearSearch = () => {
    setList(true);
  };

  return (
    <>
      <AdminHeader handleLogout={handleLogout} />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 order-2 order-lg-1">
            <h2 style={{ textAlign: "center", color: "white" }}>USERS</h2>

            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="p-10 w-100">
                <div className="search_container">
                  <input
                    placeholder="search..."
                    type="text"
                    id="searchItem"
                    className="search_user"
                  />
                  <button className="btn_user" onClick={searchUser}>
                    Search
                  </button>
                </div>
                <br />
                {list ? (
                  <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                    <thead className="hide-small">
                      <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>Thumbnail</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>Reward Fee</th>
                        <th>Balance</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users?.map((item) => (
                        <tr key={item.index}>
                          <td className="hide-small">
                            {item.FeatureImage ? (
                              <img
                                className="thumbnail-small"
                                src={item?.FeatureImage}
                                alt={item.userName}
                              />
                            ) : (
                              <img
                                className="thumbnail-small"
                                src="httpss://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
                              />
                            )}
                          </td>
                          <td className="list-title">
                            <div className="text-start w-75">
                              <h6 className="title">{item.firstName}</h6>
                            </div>
                          </td>
                          <td className="list-title">
                            <div className="text-start w-75">
                              <h6 className="title">{item.lastName}</h6>
                            </div>
                          </td>

                          <td className="list-title">
                            <div className="text-start w-75">
                              <h6 className="title">{item.email}</h6>
                            </div>
                          </td>
                          <td className="list-title">
                            <div className="text-start w-75">
                              <h6 className="title">{item.rewardFee}</h6>
                            </div>
                          </td>
                          <td className="list-title">
                            <div className="text-start w-75">
                              <h6 className="title">{item.balance}</h6>
                            </div>
                          </td>

                          <td>
                            <div className="row">
                              <Link
                                style={{ flex: "1" }}
                                to={`/admin/edit-user/${item.userId}`}
                              >
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="https://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </Link>
                            </div>
                          </td>
                          {/* {excerpt(item.description, 120)} */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                    <thead className="hide-small">
                      <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>Thumbnail</th>
                        <th>First name</th>
                        <th>Last name</th>
                        <th>Email</th>
                        <th>Reward Fee</th>
                        <th>Balance</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="hide-small">
                          {search?.FeatureImage ? (
                            <img
                              className="thumbnail-small"
                              src={search?.FeatureImage}
                              alt={search?.userName}
                            />
                          ) : (
                            <img
                              className="thumbnail-small"
                              src="httpss://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg"
                            />
                          )}
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search?.firstName}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search?.lastName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search?.email}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search?.rewardFee}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start w-75">
                            <h6 className="title">{search?.balanace}</h6>
                          </div>
                        </td>

                        <td>
                          <div className="row">
                            <svg
                              style={{
                                cursor: "pointer",
                                flex: "1",
                              }}
                              xmlns="https://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                              onClick={() => handleDeleteUser(search?.userId)}
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                              <path
                                fillRule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                              />
                            </svg>
                            <Link
                              style={{ flex: "1" }}
                              to={`/admin/edit-user/${search?.userId}`}
                            >
                              <svg
                                style={{ cursor: "pointer" }}
                                xmlns="https://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </Link>
                          </div>
                        </td>
                        {/* {excerpt(item.description, 120)} */}
                      </tr>
                    </tbody>
                  </table>
                )}
                <br />
                <h2 style={{ textAlign: "center", color: "white" }}>
                  BANKS FOR PAYMNET
                </h2>
                <div class="card ">
                  <br />
                  <div className="bankform">
                    <input
                      type="text"
                      className="name"
                      name="name"
                      value={Name}
                      placeholder="Bank Name"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="name"
                      name="account"
                      value={Account}
                      placeholder="Bank Account"
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="name"
                      name="bank"
                      value={Bank}
                      placeholder="Account Number"
                      onChange={handleChange}
                    />
                    <button onClick={handleAddBank} className="add_bank">
                      Add Bank
                    </button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminContent user={user} />
    </>
  );
};

export default AdminHome;
