import { React, useState, useEffect } from "react";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import axios from "axios";

const AdminPremiumOrders = ({ user }) => {
  const [orders, setOrders] = useState([]);
  // get all orders
  useEffect(() => {
    const fetchReviesw = async () => {
      const res = await axios.get(
        `https://api.areviewcenter.com/products/orders`
      );
      setOrders(res.data);
    };
    fetchReviesw();
  }, []);

  const handleApprove = async (itemid) => {
    if (window.confirm("are you sure you want to approve this?")) {
      const res = await axios
        .put(`https://api.areviewcenter.com/products/orders`, {
          approved: true,
          orderIdentifyer: itemid,
        })
        .then((res) => {
          if (res.status === 200) {
            // addcredit();
            console.log(res.data);
          }
        })
        .catch(function (error) {
          console.log(error.response?.data);
        });
    } else {
      alert("cancelled");
    }
    console.log(itemid);
  };

  return (
    <>
      <AdminHeader user={user} />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <center>
          <h2>ORDERS</h2>
        </center>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 pt-10 order-2 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="p-10 w-100">
                <br />
                <table className=" table table-hover table-rounded table-striped border gy-7 gs-7">
                  <thead className="hide-small">
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                      <th>User</th>
                      <th>Amount Paid</th>
                      <th>Amount Gained</th>
                      <th>Status</th>
                      <th>% Applied</th>
                      <th>Time Selected</th>
                      <th>Total Gained</th>
                      <th>Approve</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.map((item) => (
                      <tr key={item.index}>
                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title">{item.userName}</h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title">{item.amount}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title">{item.amountGained}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title">
                              {item.approved ? "Approved" : "Unapproved"}
                            </h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title"> {item.percentage} %</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title">
                              {item.minutesSelected} Min
                            </h6>
                          </div>
                        </td>

                        <td className="list-title">
                          <div className="text-start  ">
                            <h6 className="title">{item.newTotal}</h6>
                          </div>
                        </td>
                        <td className="list-title">
                          <div className="text-start  ">
                            <button
                              onClick={() =>
                                handleApprove(item.orderIdentifyer)
                              }
                              className="btn_approve"
                            >
                              Approve
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminContent user={user} />
    </>
  );
};

export default AdminPremiumOrders;
