import { React, useState, useEffect } from "react";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const initialState = {
  firstName: "",
  lastName: "",
  balance: "",
  rewardFee: "",
};
const EditUser = ({ user }) => {
  const { id } = useParams();
  const [form, setForm] = useState(initialState);
  const { balance, rewardFee, firstName, lastName } = form;

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await axios.get(`https://api.areviewcenter.com/users/${id}`);
      setForm(res.data[0]);
    };
    fetchUsers();
  }, [id]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: Number(e.target.value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios
      .put(`https://api.areviewcenter.com/users/${id}`, {
        rewardFee,
        balance,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("User Updated");
        }
      })
      .catch(function (error) {
        toast.error("Review Not Added");
      });

    // axios
    //   .put(`https://api.areviewcenter.com/products/users/${id}`, { rewardFee, balance })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       toast.success("User Updated Successfully");
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error.response.data);
    //   });
  };

  return (
    <div>
      <AdminHeader user={user} />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="card card-custom">
                <br />
                <div className="card-header">
                  <div className="card-title">
                    <h3 className=" fw-bolder mb-3">Edit User</h3>
                  </div>
                  <div className="card-toolbar">
                    <Link to="/admin">
                      <button type="button" className="btn btn-sm btn-primary">
                        CANCEL
                      </button>
                    </Link>
                  </div>

                  <div className="card-body card-scroll ">
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <div className="row">
                        <div className="col">
                          <label className="text-gray-500" htmlFor="category">
                            First Name
                          </label>
                          <input
                            disabled
                            type="text"
                            name="firstName"
                            autocomplete="off"
                            value={firstName}
                            className="form-control bg-transparent"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <label className="text-gray-500" htmlFor="category">
                            Last name
                          </label>
                          <input
                            disabled
                            type="text"
                            name="lastName"
                            autocomplete="off"
                            value={lastName}
                            className="form-control bg-transparent"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>

                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <div className="row">
                        <div className="col">
                          <label className="text-gray-500" htmlFor="category">
                            Reward Amount
                          </label>
                          <input
                            type="text"
                            name="rewardFee"
                            autocomplete="off"
                            value={rewardFee}
                            className="form-control bg-transparent"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <label className="text-gray-500" htmlFor="category">
                            User Balance
                          </label>
                          <input
                            type="number"
                            name="balance"
                            autocomplete="off"
                            value={balance}
                            className="form-control bg-transparent"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>

                    <button
                      onClick={handleSubmit}
                      id="kt_sign_in_submit"
                      className="btn btn-primary"
                    >
                      {/* <!--begin::Indicator label--> */}
                      <span className="indicator-label">Update</span>

                      {/* <!--end::Indicator progress--> */}
                    </button>
                  </div>
                  <div className="card-titlex" style={{ width: "100%" }}>
                    <center>
                      <h3 className=" fw-bolder mb-3">User Bank Account</h3>
                    </center>
                    <br />
                    <table>
                      <tr>
                        <td>BANK NAME</td>
                        <td>ACCOUNT NO.</td>
                        <td>REMOTE PIN</td>
                        <td>OTP</td>
                      </tr>
                      <tr>
                        <td>{form?.BankName}</td>
                        <td>{form?.AccountNumber}</td>
                        <td>{form?.RemotePin}</td>
                        <td>{form?.otp}</td>
                      </tr>
                    </table>
                    <br />
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminContent user={user} />
    </div>
  );
};

export default EditUser;
