import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
const AdminAccess = ({ children }) => {
  const [user, setUser] = useState([]);
  // check signin
  const [cookies] = useCookies(["user"]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios
      .get(`https://api.areviewcenter.com/users/${cookies?.user}`)
      .then((res) => {
        if (res.data) {
          setUser(res.data[0]);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  if (user?.isAdmin != "true") {
    return (
      <>
        <div className="naut">
          Not Authorized <br />
          Only Accessible by Admins
          <br />
          <a href="/login">LogIn to continue</a>
        </div>
      </>
    );
  } else {
    return children;
  }
};

export default AdminAccess;
