import React, { useEffect, useState } from "react";
import AmazonHeader from "../components/AmazonHeader";
import LatestProduct from "../components/LatestProduct";
import AmazonFooter from "../components/AmazonFooter";
import NotificationSlider from "../components/NotificationSlider";
import axios from "axios";

const Amazon = () => {
  const [product, setProduct] = useState([]);
  useEffect(() => {
    const fetchProduct = async () => {
      const res = await axios.get(`https://api.areviewcenter.com/products`);
      setProduct(res.data);
    };
    fetchProduct();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AmazonHeader />
      <NotificationSlider />
      <br />
      <br />
      <LatestProduct products={product} />
      <AmazonFooter />
    </div>
  );
};

export default Amazon;
