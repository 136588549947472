import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const initaialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Signup = () => {
  const [input, setInput] = useState(initaialState);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // console.log(input);

  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .post("https://api.areviewcenter.com/auth/register", input)
      .then((res) => {
        if (res.data === "user has been created") {
          navigate("/login");
        }
      })
      .catch(function (error) {
        setError(error.response.data);
      });
  };
  // const handleAuth = async (e) => {
  //   e.preventDefault();y

  //   if (firstName && lastName && email && password) {
  //     const { user } = await createUserWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //     );
  //     await updateProfile(user, { displayName: `${firstName} ${lastName}` });
  //     await addDoc(collection(db, "Users"), {
  //       userName: user.displayName,
  //       email: user.email,
  //       userId: user.uid,
  //       rewardFee: "",
  //       isAdmin: false,
  //       balance: 0,
  //       itemsPurchased: [],
  //       date: serverTimestamp(),
  //     });
  //   } else {
  //     return toast.error("All fields are mandatory to fill");
  //   }

  //   navigate("/login");
  // };
  return (
    <div className="container-fluid mb-4">
      <div className="container">
        <br />
        <br />
        <br />
        <div className="col-12 text-center">
          <div className="row h-100 justify-content-center align-item-center">
            <div className="col-10 col-md-8 col-lg-4 primary-bg">
              <div className="text-center heading py-2 text-light">Sign Up</div>
              {error && <p className="error-msg">{error}</p>}
              <div className="text-center justify-content-center mt-2 pt-2">
                <form className="row">
                  <div className="col-6 py-3">
                    <input
                      name="firstName"
                      onChange={handleChange}
                      type="text"
                      className="form-control input-text-box"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-6 py-3">
                    <input
                      name="lastName"
                      onChange={handleChange}
                      type="text"
                      className="form-control input-text-box"
                      placeholder="Last Name"
                    />
                  </div>

                  <div className="col-12 py-3">
                    <input
                      name="email"
                      onChange={handleChange}
                      type="email"
                      className="form-control input-text-box"
                      placeholder="email"
                    />
                  </div>
                  <div className="col-12 py-3">
                    <input
                      name="password"
                      onChange={handleChange}
                      type="password"
                      className="form-control input-text-box"
                      placeholder="password"
                    />
                  </div>

                  <div className="col- py-3">
                    <input
                      name="confirmPassword"
                      onChange={handleChange}
                      type="password"
                      className="form-control input-text-box"
                      placeholder="Confirm Password"
                    />
                  </div>

                  <div className="col-12 py-3 text-center">
                    <button
                      type="submit"
                      className="btn btn-sign-in"
                      onClick={handleSubmit}
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
