import { React, useState, useEffect } from "react";
import AdminHeader from "../components/AdminHeader";
import AdminContent from "../components/AdminContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const initialState = {
  code: "",
  image: "",
  premium: "",
  available: "no",
  title: "",
};
const Editproducts = ({ user }) => {
  const { id } = useParams();
  const [form, setForm] = useState(initialState);
  const { title, image, premium, available, code } = form;

  const navigate = useNavigate();
  useEffect(() => {
    const fetchProduct = async () => {
      const res = await axios.get(
        `https://api.areviewcenter.com/products/${id}`
      );
      setForm(res.data[0]);
    };
    fetchProduct();
  }, [id]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios
      .put(`https://api.areviewcenter.com/products/${id}`, {
        title,
        available,
        premium,
        code,
        image,
      })
      .then((res) => {
        if (res.status === 200) {
          navigate("/admin/products");
        }
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });
  };

  return (
    <div>
      <AdminHeader user={user} />
      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="card card-custom">
                <br />
                <div className="card-header">
                  <div className="card-title">
                    <h3 className=" fw-bolder mb-3">Edit Product</h3>
                  </div>
                  <div className="card-toolbar">
                    <Link to="/admin">
                      <button type="button" className="btn btn-sm btn-primary">
                        CANCEL
                      </button>
                    </Link>
                  </div>

                  <div className="card-body card-scroll ">
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label className="text-gray-500" htmlFor="title">
                        Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        autocomplete="off"
                        value={title}
                        className="form-control "
                        onChange={handleChange}
                      />

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label className="text-gray-500" htmlFor="title">
                        Image
                      </label>
                      <img src={image} alt="" className="w-100" />
                      <br />
                      <input
                        type="text"
                        name="image"
                        autocomplete="off"
                        value={image}
                        className="form-control "
                        onChange={handleChange}
                      />

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col">Avaialble for review?</div>
                      <div className="col">
                        <input
                          onChange={handleChange}
                          className="form-check-input"
                          type="radio"
                          name="available"
                          value="yes"
                          checked={available === "yes"}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        >
                          Yes&nbsp;
                        </label>
                        <input
                          onChange={handleChange}
                          className="form-check-input"
                          type="radio"
                          name="available"
                          value="no"
                          checked={available === "no"}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckChecked"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <br />
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <div className="row">
                        <div className="col">
                          <label className="text-gray-500" htmlFor="category">
                            Product Code
                          </label>
                          <input
                            type="text"
                            name="code"
                            autocomplete="off"
                            value={code}
                            className="form-control bg-transparent"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>

                    <button
                      onClick={handleSubmit}
                      id="kt_sign_in_submit"
                      className="btn btn-primary"
                    >
                      {/* <!--begin::Indicator label--> */}
                      <span className="indicator-label">Update</span>

                      {/* <!--end::Indicator progress--> */}
                    </button>
                  </div>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminContent user={user} />
    </div>
  );
};

export default Editproducts;

// const Editproducts = ({ user, handleLogout }) => {
//   const [form, setForm] = useState(initialState);
//   const [product, setProduct] = useState([]);
//   const { code, image, premium, available, title, reviewPrice } = form;
//   const { proId } = useParams();

//   useEffect(() => {
//     getUserDetails();
//   }, []);

//   const getUserDetails = async () => {
//     const docRef = doc(db, "Products", proId);
//     const snapshot = await getDoc(docRef);
//     if (snapshot.exists()) {
//       setProduct({ ...snapshot.data() });
//       console.log(product);
//       console.log(proId);
//     }
//   };

//   return (
//     <div>
//       <AdminHeader user={user} />
//       <div
//         className="wrapperx d-flex flex-column flex-row-fluid"
//         id="kt_wrapper"
//       >
//         <div className="d-flex flex-column flex-lg-row flex-column-fluid">
//           <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1">
//             <div className="d-flex flex-center flex-column flex-lg-row-fluid">
//               <div className="card card-custom">
//                 <br />
//                 <div className="card-header">
//                   <div className="card-title">
//                     <h3 className=" fw-bolder mb-3">Edit Product</h3>
//                   </div>
//                   <div className="card-toolbar">
//                     <Link to="/admin">
//                       <button type="button" className="btn btn-sm btn-primary">
//                         CANCEL
//                       </button>
//                     </Link>
//                   </div>

//                   <br />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <AdminContent />
//     </div>
//   );
// };

// export default Editproducts;
