import React, { useEffect, useState } from "react";
import AdminContent from "../components/AdminContent";
import AdminHeader from "../components/AdminHeader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";

const initialState = {
  title: "",
  image: "",
  premium: "no",
  code: "",
  available: "yes",
};

const Create = ({ user, handleLogout }) => {
  const [form, setForm] = useState(initialState);
  const { title, premium, image, code, available } = form;
  //const [image, setImage] = useState(initialState);

  // push to top page after loadings
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // const uploadImage = async (e) => {
  //   e?.preventDefault();
  //   const formData = new FormData();
  //   formData.append("file", image);
  //   const res = await axios
  //     .post("https://api.areviewcenter.com/products/uploads", formData)
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error.response?.data);
  //     });
  // };

  const handleSUbmit = async (e) => {
    e.preventDefault();

    const res = await axios
      .post("https://api.areviewcenter.com/products", {
        title,
        available,
        premium,
        code,
        // image: image ? imgUrl : "",
        image,
      })
      .then((res) => {
        if (res.status === 200) {
          navigate("/admin");
        }
      })
      .catch(function (error) {
        console.log(error.response?.data);
      });
  };
  const handleFeatured = (e) => {
    setForm({ ...form, premium: e.target.value });
  };
  const handleAvailable = (e) => {
    setForm({ ...form, available: e.target.value });
  };

  return (
    <>
      <AdminHeader user={user} handleLogout={handleLogout} />

      <div
        className="wrapperx d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-5 order-2 order-lg-1">
            <div className="card card-custom">
              <div className="card-header">
                <div className="card-title">
                  <h1 className=" fw-bolder mb-3">Create Product</h1>
                </div>
                <div className="card-toolbar">
                  <Link to="/admin">
                    <button type="button" className="btn btn-sm btn-primary">
                      CANCEL
                    </button>
                  </Link>
                </div>
              </div>
              <div className="card-scroll">
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                  <div className="center-card">
                    <div className="fv-row mb-8 fv-plugins-icon-container">
                      <label className="text-gray-500" htmlFor="title">
                        Product Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        autocomplete="off"
                        value={title}
                        className="form-control bg-transparent"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-10">
                          <div className="row">
                            <div className="col">Premium Product?</div>
                            <div className="col">
                              <input
                                onChange={handleFeatured}
                                className="form-check-input"
                                type="radio"
                                name="featured"
                                value="yes"
                                checked={premium === "yes"}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckChecked"
                              >
                                Yes&nbsp;
                              </label>
                              <input
                                onChange={handleFeatured}
                                className="form-check-input"
                                type="radio"
                                name="premium"
                                value="no"
                                checked={premium === "no"}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckChecked"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col">AVaialble for review?</div>
                            <div className="col">
                              <input
                                onChange={handleAvailable}
                                className="form-check-input"
                                type="radio"
                                name="available"
                                value="yes"
                                checked={available === "yes"}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckChecked"
                              >
                                Yes&nbsp;
                              </label>
                              <input
                                onChange={handleAvailable}
                                className="form-check-input"
                                type="radio"
                                name="available"
                                value="no"
                                checked={available === "no"}
                              />
                              <label
                                className="form-check-label"
                                for="flexCheckChecked"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="fv-row mb-8 fv-plugins-icon-container">
                      <div class="mb-10">
                        <label>Product Image</label>
                        <img src={image} alt="" className="w-100" />
                        <input
                          type="text"
                          name="image"
                          autocomplete="off"
                          className="form-control bg-transparent"
                          // onChange={(e) => setImage(e.target.files[0])}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <label htmlFor="code">Enter product code</label>
                    <input
                      id="code"
                      type="text"
                      name="code"
                      autocomplete="off"
                      value={code}
                      className="form-control bg-transparent"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-custom">
              <div className="card-header">
                <div className="card-toolbar">
                  <div
                    onClick={handleSUbmit}
                    id="kt_sign_in_submit"
                    className="btn btn-sm btn-primary"
                  >
                    CREATE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminContent user={user} />
    </>
  );
};

export default Create;
