import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

const initaialState = {
  email: "",
  password: "",
};

const Auth = () => {
  const [input, setInput] = useState(initaialState);
  const [cookies, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // push to top page after loading
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("https://api.areviewcenter.com/auth/login", input)
      .then((res) => {
        if (res.data.login) {
          const email = res.data.email;
          setCookie("user", email, {
            path: "/",
            maxAge: 3600,
          });
          navigate("/");
        } else {
          const message = res.data.message;
          setError(message);
        }
        setLoading(false);
      })
      .catch((err) => setError(err.response?.data));
  };

  return (
    <div className="container-fluid mb-4">
      <div className="container">
        <br />
        <br />
        <br />
        <div className="col-12 text-center">
          <div className="row h-100 justify-content-center align-item-center">
            <div className="col-10 col-md-8 col-lg-4 primary-bg">
              {error && <p className="error-msg">{error}</p>}
              <form className="row">
                <div className="col-12 py-3">
                  <input
                    name="email"
                    onChange={handleChange}
                    type="email"
                    className="form-control input-text-box"
                    placeholder="email"
                  />
                </div>
                <div className="col-12 py-3">
                  <input
                    name="password"
                    onChange={handleChange}
                    type="password"
                    className="form-control input-text-box"
                    placeholder="password"
                  />
                </div>

                <div className="col-12 py-3 text-center">
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn
                     btn-sign-in"
                  >
                    {loading ? <div class="abloader"></div> : " Sign In"}
                  </button>
                </div>
              </form>
              <span style={{ color: "white" }}>Dont have an account?</span>
              <a
                href="/register"
                style={{
                  marginLeft: "5px",
                  color: "white",
                  textDecoration: "underline",
                }}
              >
                here
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
